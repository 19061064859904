import React, { useState } from 'react';
import './App.css';
import { Home, Login, Spoilers } from './pages';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Footer, Navbar } from './components';
import FeedbackModal from './components/FeedbackModal';

function App() {
  const [searchTerm, setSearchTerm] = useState('');

  const [modalShow, setModalShow] = useState(false);
  const [feedback, setFeedback] = useState({
      reason: '',
      name: '',
      email: '',
      message: ''
  });

  const handleSubmit = (e) => {
      e.preventDefault();
      console.log(feedback);
      setModalShow(false); // Close the modal after submitting
  };

  return (
    <Router>
      <div className="App">
        <div className="App-content">
          <Navbar searchTerm={searchTerm} />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home setSearchTerm={setSearchTerm} />} />
            <Route path="/spoilers" element={<Spoilers />} />
          </Routes>
        </div>
        <Footer />
        {/* <button onClick={() => setModalShow(true)} class="gradient-button">Give Feedback</button>
            <FeedbackModal
                show={modalShow}
                handleClose={() => setModalShow(false)}
                feedback={feedback}
                setFeedback={setFeedback}
                handleSubmit={handleSubmit}
            /> */}
      </div>
    </Router>
  );
}

export default App;
