import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../LandingPage.module.css';


const CardPrintsDisplay = ({ card }) => {
  const [sets, setSets] = useState([]);
  const [selectedPrint, setSelectedPrint] = useState(card);
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false); // Added state for checking if search has been performed

  const handleCardFound = (foundCard) => {
    setSelectedPrint(foundCard);
    setSearched(true); // Search has been performed
  };

  useEffect(() => {
    const fetchPrints = async () => {
      setLoading(true);
      let nextPage = card.prints_search_uri;
      const fetchedSets = [];
      
      while (nextPage) {
        try {
          const response = await axios.get(nextPage);
          fetchedSets.push(...response.data.data);
          nextPage = response.data.has_more ? response.data.next_page : null;
        } catch (error) {
          console.error(`Error fetching prints: ${error.message}`);
          break;
        }
      }
      
      setSets(fetchedSets);
      setLoading(false);
    };

    fetchPrints();
  }, [card]);

  const handlePrintSelect = (selectedSet) => {
    const foundPrint = sets.find((set) => set.id === selectedSet.id);
    setSelectedPrint(foundPrint);
    scrollToTop();
  };
  
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={styles.mainContainer}>
      <h2 className={styles.logo}>{selectedPrint.name}</h2>
      <img
        src={selectedPrint.image_uris.normal}
        alt={selectedPrint.name}
        className={styles.unoCard}
      />
      <p>Average price: ${selectedPrint.prices.usd || ' Not available'} </p>
      {loading ? (
        <div className={styles.loaderOverlay}>
          <div className={styles.pingLoader}></div>
        </div>
      ) : (
        <div>
          <h3 className={styles.setsHeading}>{selectedPrint.name} has been Printed in:</h3>
          {sets.map((set) => (
            <div
              key={set.id}
              className={styles.cardSetNames}
              onClick={() => handlePrintSelect(set)}
            >
              <h4>{set.set_name}</h4>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CardPrintsDisplay;
