import React from 'react';
import styles from '../styles/Login.module.css';
import { FcGoogle } from 'react-icons/fc';
import { auth, db } from '../lib/firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // import useNavigate

function Login() {
  const navigate = useNavigate(); // initialize useNavigate

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
  
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info
        const user = result.user;
  
        // Get the current time.
        const currentTime = new Date();
  
        setDoc(doc(db, 'users', user.uid), {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          lastLoginTime: currentTime
        }, { merge: true }) // merge true to avoid overwriting data
          .then(() => {
            navigate('/');
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        // Handle Errors here.
        console.error(error);
      });
  };
  

  return (
    <div className={styles.landingPage}>
      <div className={styles.mainContainer}>
        <h1 className={styles.logo}>Welcome to MTG Reprints</h1>
        <p className={styles.subheader}>Please sign in to continue</p>

        <div className={styles.searchContainer}>
          <button className={styles.googleSignInButton} onClick={signInWithGoogle}>
            <FcGoogle size={24} />
            Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
