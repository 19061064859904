import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import styles from '../LandingPage.module.css';

const UnoCardSearch = ({ onCardFound, setSearchTerm }) => {
  const [cardSearchTerm, setCardSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [cardNotFound, setCardNotFound] = useState(false);

  const searchCardPrints = async () => {
    try {
      const response = await axios.get(`https://api.scryfall.com/cards/search?q=${encodeURIComponent(cardSearchTerm)}`);
      if (response.data.data) {
        setSuggestions(response.data.data.map(card => card.name).slice(0, 5));
        if (response.data.data.length === 1) {
          onCardFound(response.data.data[0]);
          setSuggestions([]);
          setCardNotFound(false);
        } else {
          onCardFound(null);
          setCardNotFound(false);
        }
      } else {
        console.error('No card found');
        onCardFound(null);
        setCardNotFound(true);
      }
    } catch (error) {
      console.error(`Error searching card: ${error.message}`);
      onCardFound(null);
      setCardNotFound(true);
    }
  };
  

  const selectSuggestion = async (suggestion) => {
    setCardSearchTerm(suggestion);
    setSuggestions([]);
    try {
      const response = await axios.get(`https://api.scryfall.com/cards/named?exact=${encodeURIComponent(suggestion)}`);
      if (response.data) {
        onCardFound(response.data);
        setCardNotFound(false);
      } else {
        console.error('No card found');
        onCardFound(null);
        setCardNotFound(true);
      }
    } catch (error) {
      console.error(`Error searching card: ${error.message}`);
      onCardFound(null);
      setCardNotFound(true);
    }
  }

  return (
    <div className={styles.searchContainer}>
      <input
        type="text"
        value={cardSearchTerm}
        onChange={(e) => {
          setCardSearchTerm(e.target.value);
          if (e.target.value !== '') {
            searchCardPrints();
          } else {
            setSuggestions([]);
          }
        }}
        placeholder="Search for a card"
        className={styles.searchInput}
      />

      {suggestions.map((suggestion, index) => (
        <div
          key={index}
          className={styles.cardNames}
          onClick={() => selectSuggestion(suggestion)}
        >
          {suggestion}
        </div>
      ))}

      {/* <button className={styles.searchButton} onClick={searchCardPrints}>Search</button> */}

      {cardNotFound && <p>No card found. Please try again.</p>}
    </div>
  );
};

export default UnoCardSearch;
