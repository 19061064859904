import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../LandingPage.module.css';
import SetDisplay from './SetDisplay';
import UnoCardSearch from './UnoCardSearch';
import CardPrintsDisplay from './CardPrintsDisplay';

import mtgrepringsLogo from '../assets/mtgreprints.png';

const CardSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sets, setSets] = useState([]);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [selectedSet, setSelectedSet] = useState(null);
  const [card, setCard] = useState(null);
  const [searchMode, setSearchMode] = useState('set'); // 'set' or 'card'

  const handleCardFound = (foundCard) => {
    setCard(foundCard);
    if (foundCard) {
      setSearched(true);
    } else {
      setSearched(false);
    }
  };

  useEffect(() => {
    const fetchSets = async () => {
      try {
        const response = await axios.get('https://api.scryfall.com/sets');
        setSets(response.data.data);
      } catch (error) {
        console.error(`Error fetching sets: ${error.message}`);
      }
    };
    fetchSets();
  }, []);

  const searchCard = async (set) => {
    setLoading(true);
    setSearched(true);
    setSearchTerm(set.name);
    setSelectedSet(set.code);
    try {
      const response = await axios.get(
        `https://manalith-server.vercel.app/scrape?cardName=${encodeURIComponent(set.name)}`
      );
      if (Array.isArray(response.data)) {
        setCards(response.data);
      } else {
        console.error('Unexpected response data format');
        setCards([]);
      }
    } catch (error) {
      console.error(`Error searching card: ${error.message}`);
      setCards([]);
    } finally {
      setLoading(false);
    }
  };

  const filteredSets = sets.filter((set) =>
    set.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.landingPage}>
      <div className={styles.mainContainer}>
        <img
          alt="MTG Reprints"
          src={mtgrepringsLogo}
          className={styles.logoImg}
        />

        {(!searched && searchMode === 'set') && (
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Search for a set to view the reprints</h1>
          </div>
        )}

        {(!searched && searchMode === 'card') && (
          <div className={styles.headingContainer}>
            <h1 className={styles.heading}>Search for a card to view the sets it's found in</h1>
          </div>
        )}

        <div className={styles.toggleContainer}>
          <button
            className={searchMode === 'set' ? styles.activeToggle : styles.inactiveToggle}
            onClick={() => { setSearchMode('set'); setSearchTerm(''); }}
          >
            Search by Set
          </button>
          <button
            className={searchMode === 'card' ? styles.activeToggle : styles.inactiveToggle}
            onClick={() => { setSearchMode('card'); setSearchTerm(''); }}
          >
            Search by Card
          </button>
        </div>

        {searchMode === 'set' && (
          <>
            <div className={styles.searchContainer}>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  if (e.target.value === '') {
                    setSearched(false);
                  }
                }}
                placeholder="Search for a set"
                className={styles.searchInput}
              />

              {!searched && (
                <h4 className={styles.setsHeading}>Recent and Suggested Sets</h4>
              )}

              {!searched && filteredSets.slice(0, 5).map((set) => (
                <div
                  className={styles.setNames}
                  key={set.code}
                  onClick={() => searchCard(set)}
                >
                  {set.name}
                </div>
              ))}
            </div>
            {selectedSet !== null && (
              <SetDisplay selectedSet={selectedSet} />
            )}
          </>
        )}

        {searchMode === 'card' && (
          <>
            <UnoCardSearch onCardFound={handleCardFound} />
            {card !== null && (
              <CardPrintsDisplay card={card} />
            )}
          </>
        )}

        {loading && (
          <div className={styles.loaderOverlay}>
            <div className={styles.pingLoader}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardSearch;
