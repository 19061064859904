import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Navbar.module.css';
import mtgreprintslogo from '../assets/mtgreprints.png';
import text from '../assets/text.png'


const Navbar = ({ user, searchTerm }) => {
  const [scrolled, setScrolled] = useState(false);

  let greeting = '';

  if (searchTerm) {
    greeting = `You are currently viewing ${searchTerm}`;
  } else {
    if (user) {
      greeting = `Welcome, ${user} to MtgReprints.`;
    } else {
      greeting = 'MtgReprints';
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let navbarClasses = [styles.navbar];
  if (scrolled) {
    navbarClasses.push(styles.scrolled);
  }

  return (
    <div className={navbarClasses.join(' ')}>
      <Link to="/" className={styles.logoLink}>
        <div className={styles.logoContainer}>
          <img alt="MTG Reprints" src={mtgreprintslogo} className={styles.logo} />
          <img  src={text} className={styles.textLogo} />
        </div>
      </Link>
      <div className={styles.links}>
        <Link to="/" className={styles.link}>
          REPRINTS
        </Link>
        <Link to="/spoilers" className={styles.link}>
          SPOILERS
        </Link>
        {/* <Link to="/profile" className={styles.link}>Profile</Link> */}
      </div>
    </div>
  );
  
  
};

export default Navbar;
