import React from 'react';
import CardSearch from '../components/CardSearch';

const Home = ({ setSearchTerm }) => {
  return (
    <div className="home">
      <CardSearch setSearchTerm={setSearchTerm} />
    </div>
  );
};

export default Home;
