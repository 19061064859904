// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, setDoc, deleteDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB0Zh2kpeszuuvrmiT9Y-Ai8qUEkh7UvwU",
  authDomain: "mtgreprints.firebaseapp.com",
  projectId: "mtgreprints",
  storageBucket: "mtgreprints.appspot.com",
  messagingSenderId: "380905845007",
  appId: "1:380905845007:web:4c75f23e72e666bd3c8e7a",
  measurementId: "G-NY3R5VX8BZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

if (isSupported()) {
  const analytics = getAnalytics(app);
}

export const auth = getAuth();
export const db = getFirestore();

// Add a new function to add a card to user's favorites
export const addFavoriteCard = async (userId, card) => {
  try {
    const userRef = doc(db, 'users', userId);
    const favoritesRef = doc(userRef, 'favorites', card.id);
    await setDoc(favoritesRef, card);
  } catch (error) {
    throw new Error('Failed to add card to favorites');
  }
};

// Add a new function to remove a card from user's favorites
export const removeFavoriteCard = async (userId, cardId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const favoritesRef = doc(userRef, 'favorites', cardId);
    await deleteDoc(favoritesRef);
  } catch (error) {
    throw new Error('Failed to remove card from favorites');
  }
};

// Add a new function to check if a card is in user's favorites
export const isCardFavorite = async (userId, cardId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const favoritesRef = doc(userRef, 'favorites', cardId);
    const snapshot = await favoritesRef.get();
    return snapshot.exists();
  } catch (error) {
    throw new Error('Failed to check favorite status');
  }
};

// Add a new function to get user's favorite cards
export const getFavoriteCards = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const favoritesSnapshot = await db.collection(userRef.path).get();
    const favoriteCards = favoritesSnapshot.docs.map((doc) => doc.data());
    return favoriteCards;
  } catch (error) {
    throw new Error('Failed to fetch favorite cards');
  }
};

export default app;