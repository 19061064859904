import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "../LandingPage.module.css";
import style from "../styles/mana-cost.css";


const SetDisplay = ({ selectedSet = "ltr" }) => {
  const [set, setSet] = useState(null);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterColors, setFilterColors] = useState([]);
  const [filterRarities, setFilterRarities] = useState([]);
  const [filterMulticolor, setFilterMulticolor] = useState(false);
  const [filterColorless, setFilterColorless] = useState(false);

  useEffect(() => {
    const fetchSet = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://api.scryfall.com/sets/${selectedSet}`
        );
        setSet(response.data);

        if (response.data.search_uri) {
          const cardList = await fetchAllCards(response.data.search_uri);
          setCards(cardList);
        }
      } catch (error) {
        console.error("Error fetching set:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedSet) {
      fetchSet();
    }
  }, [selectedSet]);

  const fetchAllCards = async (searchUri) => {
    const cardList = [];

    let nextPage = searchUri;
    while (nextPage) {
      try {
        const response = await axios.get(nextPage);
        const fetchedCards = response.data.data;

        const filteredCards = fetchedCards.filter(
          (card) =>
            card.reprint &&
            !["Plains", "Island", "Mountain", "Forest", "Swamp"].includes(
              card.name
            )
        );

        cardList.push(...filteredCards);
        nextPage = response.data.next_page;
      } catch (error) {
        console.error("Error fetching card list:", error);
        break;
      }
    }
    return cardList;
  };

  const handleColorFilterChange = (color) => {
    if (filterColors.includes(color)) {
      setFilterColors(filterColors.filter((item) => item !== color));
    } else {
      setFilterColors([...filterColors, color]);
    }
  };

  const handleRarityFilterChange = (rarity) => {
    if (filterRarities.includes(rarity)) {
      setFilterRarities(filterRarities.filter((item) => item !== rarity));
    } else {
      setFilterRarities([...filterRarities, rarity]);
    }
  };

  const handleColorlessFilterChange = () => {
    setFilterColorless(!filterColorless);
  };

  const isColorChecked = (color) => filterColors.includes(color);
  const isRarityChecked = (rarity) => filterRarities.includes(rarity);

  const filteredCards = cards.filter(
    (card) =>
      (filterColors.length === 0 ||
        filterColors.includes(card.color_identity[0])) &&
      (filterRarities.length === 0 || filterRarities.includes(card.rarity)) &&
      (!filterMulticolor || card.color_identity.length > 1) &&
      (filterColorless ? card.color_identity.length === 0 : true)
  );

  if (!set) {
    return (
      <div className={styles.loaderOverlay}>
        <div className={styles.pingLoader}></div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.filterSection}>
        <div className={styles.filterContainer}>
         <div className={styles.colorFilterContainer}>
  <h3>Filter by Color</h3>
  <div className={styles.colorFilterRow}>
    {["W", "U", "B", "R", "G", "C"].map((color) => (
      color !== "C" ?
        <label
          key={color}
          className={`${styles.colorFilterItem} ${
            isColorChecked(color) ? styles.checked : ""
          }`}
          onClick={() => handleColorFilterChange(color)}
        >
          <span
            className={`mana medium s${color.toLowerCase()} shadow`}
          ></span>
        </label>
        :
        <label
          className={`${styles.colorFilterItem} ${
            filterColorless ? styles["checked-rarity"] : ""
          } ${filterColorless ? "colorlessChecked" : ""}`}
          onClick={handleColorlessFilterChange}
        >
          <span
            className={`mana medium sc shadow ${
              filterColorless ? styles["checked-rarity"] : ""
            }`}
          ></span>
        </label>
    ))}
  </div>
</div>

          <div className={styles.rarityFilterContainer}>
            <h3>Filter by Rarity</h3>
            <div className={styles.rarityFilterOptions}>
              {["common", "uncommon", "rare", "mythic"].map((rarity) => (
                <label
                  key={rarity}
                  className={`${styles.checkboxLabel} ${
                    isRarityChecked(rarity) ? styles["checked-rarity"] : ""
                  }`}
                  onClick={() => handleRarityFilterChange(rarity)}
                >
                  {capitalizeFirstLetter(rarity)}
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>

      <p>{set.description}</p>
      {loading ? (
        <div className={styles.loaderOverlay}>
          <div className={styles.pingLoader}></div>
        </div>
      ) : (
        <div style={cardContainerStyle}>
          {filteredCards.length > 0 ? (
            filteredCards.map((card) => (
              <div key={card.id} style={cardItemStyle}>
                <img
                  src={getImageUrl(card)}
                  alt={card.name}
                  className="Corners"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            ))
          ) : (
            <div>No cards found in this set.</div>
          )}
        </div>
      )}
    </div>
  );
};

function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export default SetDisplay;

const getImageUrl = (card) => {
  if (card.image_uris && card.image_uris.large) {
    return card.image_uris.large;
  } else if (
    card.card_faces &&
    card.card_faces[0].image_uris &&
    card.card_faces[0].image_uris.large
  ) {
    return card.card_faces[0].image_uris.large;
  } else if (
    card.card_faces &&
    card.card_faces.length > 0 &&
    card.card_faces[0].image_uris &&
    card.card_faces[0].image_uris.normal
  ) {
    return card.card_faces[0].image_uris.normal;
  } else if (
    card.card_faces &&
    card.card_faces.length > 0 &&
    card.card_faces[0].image_uris_large &&
    card.card_faces[0].image_uris_large.normal
  ) {
    return card.card_faces[0].image_uris_large.normal;
  } else {
    return ""; // Replace with a default image URL if available
  }
};

const cardContainerStyle = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const cardItemStyle = {
  width: "245px",
  height: "341px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
};
