import React from 'react';

function Footer() {
  const d = new Date();
  let year = d.getFullYear();
  return (
    <div>
      <footer className="App-footer">
        <p>
        © Mtg Reprints {year} | Powered by{' '}
          <a target="_blank" href="https://www.nusoft.co.za" style={{ fontWeight: 'bold', textDecoration: 'none' }}>
            <span
              style={{
                background: 'linear-gradient(to right, #F7971E, #FFD200)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                color: 'transparent',
              }}
            >
              Nusoft
            </span>
          </a>
        </p>
      </footer>
    </div>
  );
}

export default Footer;
